<template>
  <div class="container">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item disabled>
        {{$vuetify.lang.t('$vuetify.worksheet.scannedWorksheet')}} {{ worksheet ? worksheet.id : "" }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-sheet v-if="worksheet" class="text-center">
      <div class="text-caption font-weight-light ma-4">
        <div>{{$vuetify.lang.t('$vuetify.base.entry')}} <span v-if="worksheet.documentNumber">{{worksheet.documentNumber}}-</span>{{ entryIndex + 1 }}</div>
      </div>

      <div v-if="entry" class="d-flex justify-space-around text-h6">
        <div>{{$vuetify.lang.t('$vuetify.base.netWeight')}}</div>
        <div>
          {{ entry.netWeight | formatNumber }}
          {{ productInfo ? productInfo.measureWeight : "" }}
        </div>
      </div>
      <div class="text-caption mt-2">
        <div>{{ entry.notes }}</div>
        <div>{{ entry.updateTime | formatDateTime }}</div>
      </div>

      <div class="my-4">
        <v-carousel height="auto" v-if="images && images.length > 0">
          <v-carousel-item
            v-for="(img, i) in images"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-img contain aspect-ratio="1.7" :src="img.src" />
            <div class="text-right text-caption ma-2">{{img.time | formatDateTime }}</div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <v-divider class="my-2" />
      <div class="text-subtitle-2 font-weight-light">{{$vuetify.lang.t('$vuetify.worksheet.worksheet')}}:</div>
      <worksheet-card :worksheet="worksheet" />
    </v-sheet>
  </div>
</template>

<script>
import { getProcessWorksheet } from "@/services/processWorksheet";
import { mapActions, mapGetters } from "vuex";
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
import { parseDomain } from "parse-domain";

export default {
  components: { WorksheetCard },
  data() {
    return {
      worksheet: null,
      entry: null,
      entryIndex: null,
      productInfo: null,
    };
  },
  computed: {
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    images() {
      if (this.entry && this.entry.files && this.entry.files.length > 0) {
        return this.entry.files.map((file) => {
          const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`;
          return { src, id: file.id, time: file.createdTime};
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain;
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    async loadProductInfo(id) {
      if (id) {
        return await this.fetchCategory(id).then((resp) => {
          this.productInfo = resp;

          return this.productInfo;
        });
      } else {
        return null;
      }
    },
  },
  async created() {
    this.id = this.$route.params.id;
    this.entryId = this.$route.params.entryId;

    getProcessWorksheet(this.id).then((response) => {
      this.worksheet = response.data;
      if (this.worksheet) {
        if (this.worksheet.productId) {
          this.loadProductInfo(this.worksheet.productId);
        }

        const entries = this.worksheet.entries;
        if (entries && entries.length > 0) {
          this.entryIndex = entries.findIndex((e) => e.id == this.entryId);
          this.entry = entries[this.entryIndex];
        }
      }
    });
  },
};
</script>
